import React, { Component } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import styles from "./normalcy-law.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import { func } from "prop-types";

export class NormalcyLaw extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(3);
  }

  onSubmit = () => {
    this.props.goToStep(4, "/modules/normalcy/normal-childhood-activities");
  };

  goBack = () => {
    this.props.goToStep(2, "/modules/normalcy/what-is-normalcy");
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - What is Normalcy"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.normalcyLawParts}>
            <b>The normalcy law</b> essentially has two parts:
            <ul>
              <li>
                Children in care have the right to be normal kids. They can
                <b> participate in normal kid activities</b> with their foster
                parent’s permission.
              </li>
              <li>
                Foster parents have the right (and responsibility){" "}
                <b>to give or withhold permission</b> for a child’s activity
                based on a <b>reasonable & prudent parenting standard</b>. They
                make decisions based on their best judgment and are not required
                to seek additional permission from others. (Except for high-risk
                activities. We’ll cover that later in this module.)
              </li>
            </ul>
            <p>Let's take a closer look at each part now.</p>
          </div>
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(NormalcyLaw);
